$white: #FFFFFF;
$black: #000000;
$offwhite: #F2F2F2;

$primary: #000000;

$orange: #FF9D39;
$yellow: #FFD800;
$blue: #F9013C;
$darkgreen: #017B40;
$green: #02CD6B;
$red: #DF0403;

$lightergrey: #F2F2F2;
$lightgrey: #f6f6f9;
$mediumgrey: #bbbbbb;
$darkgrey: #222222;
$darkergrey: #2E2E2E;

$neutral20: #D9D9D9;

// PDP custom colors

$pdpBlack: #161616;
$pdpRed: #D82898;
$pdpRedDark: #820DA6;
$textGrey: #9E9E9E;

