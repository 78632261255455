@import '~styles/sass-includes';
$boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);

.listing-gallery-menu-container {
  margin-right: 16px;
  z-index: +1;
  // media queries
  @include breakpoint($mobile-lg) {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -45px;
    display: flex;
    justify-content: center;
  }
}

.listing-gallery-menu {
  display: flex;
  flex-direction: column;
  // media queries
  @media only screen and (max-width: $tablet-lg) and (min-width: $tablet-sm) {
    flex-direction: row;
    align-self: flex-end;
  }
  @include breakpoint($mobile-lg) {
    flex-direction: row;
  }
}

.listing-gallery-menu-item {
  width: 6rem;
  height: 6rem;
  padding: 0;
  margin: 0 0 16px 0;
  cursor: pointer;
  overflow: hidden;
  border: 0.1rem solid var(--neutral-20);
  border-radius: 8px;
  &.is-active {
    border-color: var(--blue);
  }
  // media queries
  @include breakpoint($desktop-sm) {
    margin: 0 1rem 1rem 0;
  }
  @include breakpoint($mobile-lg) {
    box-shadow: $boxShadow;
    width: 5rem;
    height: 5rem;
  }

  img {
    object-fit: cover;
    display: block;
  }
}

.listing-gallery-viewport {
  // media queries
  @include breakpoint($mobile-lg) {
    width: calc(100% + 8rem);
    margin-left: -4rem;
  }
  @include breakpoint($mobile-md) {
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
  }
  @include breakpoint($mobile-sm) {
    width: calc(100% + 3.2rem);
    margin-left: -1.6rem;
  }
}
